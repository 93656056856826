import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { CurrentLangSwitched } from '@app/store';
import { Store } from '@ngxs/store';

const LANG_KEY = 'lang';

export const LanguageGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const selectedLang = route.queryParamMap.get(LANG_KEY);

  if (selectedLang) {
    return store.dispatch(new CurrentLangSwitched(selectedLang));
  }

  return true;
};
