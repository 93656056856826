import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStateSelectors } from '@app/store';
import { CognitoService } from '@hae/auth';
import { Store } from '@ngxs/store';
import { combineLatestWith, map } from 'rxjs/operators';

export const ConfirmedGuard: CanActivateFn = () => {
  const router = inject(Router);
  const cognitoService = inject(CognitoService);
  const store = inject(Store);

  return cognitoService.isLoggedIn().pipe(
    combineLatestWith(store.select(AuthStateSelectors.isConfirmed)),
    map(([isLoggedIn, isConfirmed]) => {
      if (isConfirmed && isLoggedIn) {
        return true;
      }
      const loginUrl = '/login';
      return router.parseUrl(loginUrl);
    }),
  );
};
