<hae-backend-style name="CSS_STYLES"></hae-backend-style>
<hae-google-tag></hae-google-tag>
@if ((isLoading$ | async) || (isUserTransferLoading$ | async)) {
  <div
    class="loading-container"
    >
    <mat-spinner></mat-spinner>
  </div>
} @else {
  <router-outlet></router-outlet>
}
