<article>
  <header>
    <hae-header></hae-header>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
</article>
<footer>
  <hae-footer></hae-footer>
</footer>
