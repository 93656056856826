import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserLogoutRequested } from '@app/store';
import { DonorControllerService } from '@hae/api';
import { DialogComponent } from '@hae/utils';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'hae-delete-modal',
  templateUrl: './delete-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogComponent, MatProgressSpinnerModule],
})
export class DeleteModalComponent extends DialogComponent {
  @ViewChild('template') templateRef!: TemplateRef<never>;

  constructor(
    private donorControllerService: DonorControllerService,
    private store: Store,
    private cdr: ChangeDetectorRef,
  ) {
    super({
      title: 'menu.deleteProfile',
      text: 'dialog.removeProfile.text',
      primaryLabel: 'menu.deleteProfile',
      secondaryLabel: 'dialog.cancel',
    });
    this.data.primaryCallback = () => this.deleteProfile();
  }

  public userLogoutRequested(): void {
    this.store.dispatch(new UserLogoutRequested(true));
  }

  deleteProfile(): void {
    this.data = {
      title: 'menu.deleteProfile',
      hideButton: true,
      template: this.templateRef,
    };
    this.cdr.detectChanges();

    this.donorControllerService
      .deleteDonorProfile()
      .pipe(
        tap(() => {
          this.data = {
            ...this.data,
            template: undefined,
            hideButton: false,
            primaryLabel: 'dialog.close',
          };
        }),
      )
      .subscribe({
        next: () => {
          this.data = {
            ...this.data,
            text: 'dialog.removeProfile.success',
          };
          this.cdr.detectChanges();
          this.userLogoutRequested();
        },
        error: () => {
          this.data = {
            ...this.data,
            text: 'dialog.removeProfile.error',
          };
          this.cdr.detectChanges();
        },
      });
  }
}
