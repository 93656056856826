import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'hae-page-not-found',
  templateUrl: './page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PageNotFoundComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {
    const state = this.location.getState() as { notFoundUrl?: string };
    if (typeof state === 'object' && state !== null && state.notFoundUrl) {
      this.location.replaceState(state.notFoundUrl);
    }
  }
}
