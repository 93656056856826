import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { URL_PATH_LOGIN } from '@app/modules/core/routing/constants';
import {
  CurrentLangSwitched,
  UserTransfer,
  UserTransferStart,
} from '@app/store';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const transferTokenKey = 'transferToken';

export const TransferGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);

  const paramMap = route.queryParamMap;

  if (paramMap.has(transferTokenKey) && paramMap.has('lang')) {
    const urlLang = paramMap.get('lang');
    return store
      .dispatch([
        new UserTransfer({ transferToken: paramMap.get(transferTokenKey) as string }),
        new UserTransferStart(),
        new CurrentLangSwitched(urlLang as string),
      ])
      .pipe(
        map(() => false),
        catchError(() => of(router.parseUrl(URL_PATH_LOGIN))),
      );
  }

  return true;
};
