import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskLoadedSuccessfully, UserLogoutRequested } from '@app/store';
import { DialogComponent } from '@hae/utils';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 */
@Injectable()
export class ErrorAuthInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private store: Store) {}

  public doLogout(): void {
    this.store.dispatch(new UserLogoutRequested());
  }

  intercept(
    request: HttpRequest<never>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.doLogout();
          this.dialog.open(DialogComponent, {
            data: {
              title: 'dialog.session.title',
              text: 'dialog.session.text',
              errorCode: '008',
            },
          });
        }

        return throwError(() => err);
      }),
      finalize(() => new TaskLoadedSuccessfully()),
    );
  }
}
