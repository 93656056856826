import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { URL_PATH_HOME } from '@app/modules/core/routing/constants';
import { AuthStateSelectors } from '@app/store';
import { CognitoService } from '@hae/auth';
import { Store } from '@ngxs/store';
import { filter, map, mergeMap, skipUntil } from 'rxjs/operators';

export const LoggedGuard: CanActivateFn = () => {
  const cognitoService = inject(CognitoService);
  const router = inject(Router);
  const store = inject(Store);
  let isUserLogged = false;

  return cognitoService.isLoggedIn().pipe(
    skipUntil(
      store
        .select(AuthStateSelectors.stateInitialized)
        .pipe(filter((stateStatus) => stateStatus)),
    ),
    mergeMap((userLogged) => {
      isUserLogged = userLogged;
      return store.select(AuthStateSelectors.isConfirmed);
    }),
    map((isConfirmed) => {
      if (isUserLogged && isConfirmed) {
        const redirectUrl = `/${URL_PATH_HOME}`;
        return router.parseUrl(redirectUrl);
      }
      return true;
    }),
  );
};
