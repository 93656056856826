import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { JwtInterceptor } from '@hae/auth';

import { ErrorAuthInterceptor } from './interceptors/error-auth.interceptor';

@NgModule({
  imports: [MatDialogModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorAuthInterceptor, multi: true },
  ],
})
export class AuthModule {}
