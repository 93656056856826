import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthStateSelectors } from '@app/store';
import { Store } from '@ngxs/store';

import { apiEntityResolver } from './abstract/api-entity.resolver';

export const DonorLookupResolver: ResolveFn<true> = () => {
  const store = inject(Store);

  return apiEntityResolver(store.select(AuthStateSelectors.donorLookup));
};
