import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterOutlet } from '@angular/router';
import { DeleteModalComponent } from '@app/shared/components/delete-modal/delete-modal.component';
import {
  AuthStateSelectors,
  CurrentLangSwitched,
  I18nStateSelectors,
  UserLogoutRequested,
} from '@app/store';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'hae-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    RouterOutlet,
    FooterComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class LoggedLayoutComponent {
  currentStateLanguage$ = this.store.select(I18nStateSelectors.currentLanguage);

  supportedLanguages$ = this.store.select(
    I18nStateSelectors.supportedLanguages,
  );

  isLanguageSwitcherDisabled$ = this.store.select(
    I18nStateSelectors.isLanguageSwitcherDisabled,
  );

  user$ = this.store.select(AuthStateSelectors.user);

  isUserTransferred$ = this.store
    .select<boolean>(AuthStateSelectors.isUserTransfer)
    .pipe(map((isUserTransferred) => !isUserTransferred));

  @ViewChild('drawer') drawer!: MatDrawer;

  showLanguagesSwitcher$ = this.supportedLanguages$.pipe(
    map((locales) => locales.length > 1),
  );

  constructor(private dialog: MatDialog, private store: Store) {}

  switchCurrentLang(selectedLang: string): void {
    this.drawer.close();
    this.store.dispatch(new CurrentLangSwitched(selectedLang));
  }

  userLogoutRequested(): void {
    this.store.dispatch(new UserLogoutRequested());
  }

  deleteDonorProfile(): void {
    this.dialog.open(DeleteModalComponent, { disableClose: true });
  }
}
