import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';
import { AuthState } from './auth/auth.state';
import { I18nState } from './i18n/i18n.state';
import { PageState } from './page/page.state';
import { TenantState } from './tenant/tenant.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([I18nState, PageState, AuthState, TenantState], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({ key: ['i18n.currentLanguage'] }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
})
export class StoreModule {}
