<mat-drawer-container>
  <mat-drawer #drawer mode="over">
    <button mat-button (click)="drawer.toggle()">
      <span><mat-icon>close</mat-icon></span>
    </button>
    <ul>
      <li routerLink="/home" (click)="drawer.close()">
        {{ "Home" | translate }}
      </li>
      @if (
        (showLanguagesSwitcher$ | async) &&
        !(isLanguageSwitcherDisabled$ | async)
        ) {
        @for (locale of supportedLanguages$ | async; track locale) {
          @if ((currentStateLanguage$ | async) !== locale) {
            <li
              (click)="switchCurrentLang(locale)"
              >
              {{ locale | translate }}
            </li>
          }
        }
      }
      <li routerLink="/privacy-policy">
        {{ "privacyPolicy.link" | translate }}
      </li>
      <li routerLink="/settings" (click)="drawer.toggle()">
        {{ "menu.settings" | translate }}
      </li>
      @if ((user$ | async)?.userType === 'DONOR') {
        <li
          (click)="deleteDonorProfile()"
          >
          {{ "menu.deleteProfile" | translate }}
        </li>
      }
      <li (click)="userLogoutRequested()">{{ "LogOut" | translate }}</li>
    </ul>
  </mat-drawer>
  <mat-drawer-content>
    <article>
      <header>
        @if (isUserTransferred$ | async) {
          <button
            mat-button
            (click)="drawer.toggle()"
            >
            <span><mat-icon>menu</mat-icon></span>
          </button>
        }
      </header>
      <main>
        <router-outlet></router-outlet>
      </main>
    </article>
  </mat-drawer-content>
</mat-drawer-container>
<footer>
  <hae-footer></hae-footer>
</footer>
