import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@app/modules/core/auth/auth.module';
import { ErrorModule } from '@app/modules/core/error/error.module';
import { routes } from '@app/modules/core/routing/routes';
import { StoreModule } from '@app/store/store.module';
import { BASE_PATH } from '@hae/api';
import { dateAdapterProvider, I18nModule } from '@hae/utils';
import { OAuthModule } from 'angular-oauth2-oidc';

import { RootComponent } from './app/core/components/root/root.component';
import { environment } from './environments/environment';

registerLocaleData(localeFr);
registerLocaleData(localeEs);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      StoreModule,
      I18nModule.forRoot('Donor360-qnr'),
      AuthModule,
      ErrorModule,
      MatProgressSpinnerModule,
      OAuthModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ),
    dateAdapterProvider,
    { provide: BASE_PATH, useValue: window.location.origin },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
