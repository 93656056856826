import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  PageStateSelectors,
  TaskLoadedSuccessfully,
  TaskLoadingStarted,
} from '@app/store';
import { BackendStyleComponent, GoogleTagComponent } from '@hae/utils';
import { Store } from '@ngxs/store';
import { interval } from 'rxjs';

@Component({
  selector: 'hae-root',
  styleUrls: ['./root.component.scss'],
  templateUrl: './root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackendStyleComponent,
    GoogleTagComponent,
    MatProgressSpinnerModule,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class RootComponent {
  public isLoading$ = this.store.select(PageStateSelectors.isLoading);

  public isUserTransferLoading$ = this.store.select(
    PageStateSelectors.isUserTransferLoading,
  );

  public taskLoadingStarted(): void {
    this.store.dispatch(new TaskLoadingStarted());
  }

  public taskLoadedSuccessfully(): void {
    this.store.dispatch(new TaskLoadedSuccessfully());
  }

  constructor(
    router: Router,
    private swUpdate: SwUpdate,
    private store: Store,
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.taskLoadingStarted();
      } else if (
        event instanceof NavigationEnd
        || event instanceof NavigationCancel
        || event instanceof NavigationError
      ) {
        this.taskLoadedSuccessfully();
      }
    });
  }

  ngOnInit(): void {
    this.swUpdate.versionUpdates.subscribe(({ type }) => {
      if (type === 'VERSION_READY') {
        window.location.reload();
      }
    });

    // Every 6 hours
    interval(6 * 60 * 60 * 1000).subscribe(() => this.swUpdate.checkForUpdate());
  }
}
